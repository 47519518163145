import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isUndefined } from 'lodash';
import { Divider, Typography, withStyles, Container } from '@material-ui/core';
import { productDefaultImage, breakPoints } from '../../../../constants';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ProductHeroImage from '../../../shared/Products/ProductHeroImage';
import ProductCategories from '../../../shared/Products/ProductCategories';
import ProductMonopolyLink from '../../../shared/Products/ProductMonopolyLink';
import ProductMonopolyPrice from '../../../shared/Products/ProductMonopolyPrice';
import ProductMonopolyNumber from '../../../shared/Products/ProductMonopolyNumber';
import ProductVintageYear from '../../../shared/Products/ProductVintageYear';
import ProductReviews from '../../ProductReviews';

import ProductMemoryListButton from '../../../shared/Products/ProductMemoryListButton';
import PrintButton from '../../../shared/PrintButton';
import ProductMainInfo from '../../ProductMainInfo';
import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '@media print': {
      display: 'block',
      position: 'relative',
    },
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    '@media print': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  imageSide: {
    justifyContent: 'center',
    display: 'flex',
    flexBasis: '100%',
    maxWidth: '100%',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      maxWidth: '50%',
      paddingTop: theme.spacing(0),
    },
    '@media print': {
      justifyContent: 'flex-end',
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  printButton: {
    display: 'none',
    float: 'right',
    fontSize: '12px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  name: {
    marginBottom: theme.spacing(2),
  },
  informations: {
    flexBasis: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
    '@media print': {
      paddingTop: '24px',
      position: 'absolute',
      top: '0',
      left: '0',
      maxWidth: '100%',
    },
  },
  basicInformation: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.productPage.informationBackground,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
    },
    '@media print': {
      backgroundColor: 'transparent',
      color: 'black',
      maxWidth: '50%',
      padding: '0 16px',
      minHeight: '340px',
      [theme.breakpoints.up('md')]: {
        marginTop: '0',
      },
    },
  },
  mainInformation: {},
  category: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  country: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    color: theme.palette.category.outlined.text,
    fontSize: '12px',
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(1),
  },
  divider: {
    height: '2px',
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white,
    '@media print': {
      display: 'none',
    },
  },
  tasteStyleCategories: {
    marginBottom: theme.spacing(2),
  },
  foodRecommendationCategories: {
    marginBottom: theme.spacing(2),
  },
  monopolyPrice: {
    marginBottom: theme.spacing(2),
  },
  monopolyNumber: {
    marginBottom: theme.spacing(2),
  },
  monopolyLink: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  shortDescription: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  listButton: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    padding: '13px 15px',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
    },
    '@media print': {
      display: 'none',
    },
  },
});

function ProductInfo({ className, classes, pageData }) {
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  return (
    <>
      <Container className={classes.container}>
        <div className={clsx(classes.root, className)}>
          <div className={classes.imageSide}>
            <ProductHeroImage productData={pageData} defaultImage={productDefaultImage.Folkofolk} />
          </div>
          <div className={classes.informations}>
            <div className={classes.basicInformation}>
              <ProductVintageYear productData={pageData} className={classes.monopolyNumber} />
              <Typography variant="h1" className={classes.name}>
                {get(pageData, 'name')}
              </Typography>
              <div className={classes.category}>
                <ProductCategories productData={pageData} />
                <Typography variant="body1" className={classes.country}>
                  {get(pageData, 'attributes.country_of_origin.value')}
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <ProductMonopolyPrice productData={pageData} className={classes.monopolyPrice} variant="h2" />
              <ProductMonopolyNumber productData={pageData} className={classes.monopolyNumber} />

              <ProductMonopolyLink productData={pageData} className={classes.monopolyLink} variant="outlined" />
              <ProductMemoryListButton productData={pageData} className={classes.listButton} variant="contained" />
              {get(pageData, 'attributes.short_description.value') && (
                <Typography variant="body1" className={classes.shortDescription}>
                  {get(pageData, 'attributes.short_description.value')}
                </Typography>
              )}
            </div>
            <PrintButton className={classes.printButton} />
            <ProductMainInfo pageData={pageData} />
          </div>
        </div>
      </Container>
      <Visible hidden={isMobile}>
        <ProductReviews pageData={pageData} />
      </Visible>
    </>
  );
}

ProductInfo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductInfo.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ProductInfo);
