/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { useAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core';

import ProtectedRoute from '../../../components/authentication/ProtectedRoute/ProtectedRoute';

import AdvancedPageTemplate from '../AdvancedPageTemplate';
import { anoraProUnprotectedPages } from '../../../constants';

import LoadingIndicator from '../../../components/shared/LoadingIndicator';

const styles = () => ({
  loading: {
    margin: '40px',
  },
});
function AnoraProAdvancedPageTemplate({ classes, ...props }) {
  const fullPath = get(props, 'data.contentfulAdvancedPage.fullPath');
  const isUnprotectedPage = includes(Object.values(anoraProUnprotectedPages), fullPath);

  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <LoadingIndicator />
      </div>
    );
  }

  if (isUnprotectedPage && !isAuthenticated && !isLoading) {
    return <AdvancedPageTemplate {...props} />;
  }

  return (
    <ProtectedRoute>
      <AdvancedPageTemplate {...props} />;
    </ProtectedRoute>
  );
}

AnoraProAdvancedPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProAdvancedPageTemplate.defaultProps = {
  data: null,
  classes: {},
};
export default withStyles(styles)(AnoraProAdvancedPageTemplate);
