import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';

import { useMemoryList } from '../../../hooks/useMemoryList';

const styles = () => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
});

function MemoryListShareButton({ className, classes, productData, ...otherProps }) {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();

  const { generateMemoryListShareUrl } = useMemoryList();

  const shareMemoryList = useCallback(async () => {
    setIsCopied(true);
    if (!isCopied) {
      const shareUrl = generateMemoryListShareUrl();
      await navigator.clipboard.writeText(shareUrl);
    }
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  }, [generateMemoryListShareUrl, isCopied]);

  return (
    <Button
      className={clsx(classes.root, className)}
      variant="outlined"
      {...otherProps}
      onClick={shareMemoryList}
      startIcon={isCopied ? <DoneIcon /> : <FileCopyIcon />}>
      {isCopied ? t('MemoryList.MemoryListShareButton.copied.label') : t('MemoryList.MemoryListShareButton.copy.label')}
    </Button>
  );
}

MemoryListShareButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
};

MemoryListShareButton.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default withStyles(styles)(MemoryListShareButton);
