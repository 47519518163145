import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isUndefined } from 'lodash';
import { withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { breakPoints } from '../../../../../constants';

import ThumbnailImage from '../../../ThumbnailImage';
import ProductLabels from '../../ProductLabels';
import ProductAwards from '../../ProductAwards';
import ProductClassification from '../../ProductClassification';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 5),
    width: '100%',
    maxWidth: '100%',
    maxHeight: '350px',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5),
      maxHeight: '620px',
    },
    '@media print': {
      maxWidth: '50%',
      padding: theme.spacing(0, 0, 0, 5),
    },
  },
  imageWrapper: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  image: {
    '@media print': {
      padding: '0',
      maxHeight: '350px',
    },
  },
});

function ProductHeroImage({ classes, className, productData, defaultImage, ...otherProps }) {
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();
  let heroImage = get(productData, 'heroImage');

  if (isEmpty(get(heroImage, 'cloudinaryImage.0.public_id'))) {
    heroImage = {
      cloudinaryImage: [{ public_id: defaultImage }],
      imageAltText: get(productData, 'name'),
      imageTitleText: get(productData, 'name'),
    };
  }

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  return (
    <div className={clsx(classes.root, className)}>
      <ProductLabels productData={productData} {...otherProps} />
      <ProductAwards productData={productData} />
      <ProductClassification productData={productData} />
      <div className={classes.imageWrapper}>
        <ThumbnailImage
          className={classes.image}
          data={heroImage}
          transformation="ProductThumbnailImage-IN-SM"
          width="auto"
          height={isMobile ? '100%' : 'auto'}
          circle={false}
        />
      </div>
    </div>
  );
}

ProductHeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultImage: PropTypes.string,
};

ProductHeroImage.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  defaultImage: false,
};

export default withStyles(styles)(ProductHeroImage);
