import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { saveAs } from 'file-saver';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Typography, withStyles, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    '@media print': {
      display: 'none',
    },
  },
});

function ProductImageDownload({ className, classes, productData }) {
  const imgurl = get(productData, 'heroImage.cloudinaryImage.0.secure_url');
  const name = get(productData, 'name');
  const { t } = useTranslation();

  const download = useCallback(() => {
    saveAs(imgurl, name);
  }, [imgurl, name]);

  if (isEmpty(imgurl)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Button download={imgurl} onClick={download} variant="outlined" startIcon={<GetApp />}>
        <Typography>{t('ProductPage.DownloadButton.label')}</Typography>
      </Button>
    </div>
  );
}

ProductImageDownload.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
};

ProductImageDownload.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default memo(withStyles(styles)(ProductImageDownload));
