import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import AnoraProProductImageDownload from './common';

function ProductImageDownload(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProProductImageDownload {...props} />;
  }

  return null;
}

export default ProductImageDownload;
