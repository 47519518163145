import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty } from 'lodash';
import { Add, Remove } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useMemoryList } from '../../../../hooks/useMemoryList';

import Button from '../../../Button';
import { addToCart, removeFromCart } from '../../../../../utils/analyticUtils';

const styles = () => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
});

function ProductMemoryListButton({ className, classes, productData, ...otherProps }) {
  const { t } = useTranslation();

  const { addProductToMemoryList, removeProductFromMemoryList, isProductInMemoryList } = useMemoryList();

  const alreadyAdded = isProductInMemoryList(productData);

  const handleButtonClick = useCallback(() => {
    if (alreadyAdded) {
      removeProductFromMemoryList(productData);
      removeFromCart(productData);
    } else {
      addProductToMemoryList(productData);
      addToCart(productData);
    }
  }, [alreadyAdded, removeProductFromMemoryList, productData, addProductToMemoryList]);

  const monopolyUrl = get(productData, 'attributes.monopoly_url.value');
  if (isEmpty(monopolyUrl)) {
    return null;
  }
  return (
    <Button
      {...otherProps}
      className={clsx(classes.root, className)}
      classes={{ root: classes.contained }}
      startIcon={alreadyAdded ? <Remove fontSize="small" /> : <Add fontSize="small" />}
      onClick={handleButtonClick}>
      {t(`ProductMemoryListButton.actions.${alreadyAdded ? 'remove' : 'add'}.label`)}
    </Button>
  );
}

ProductMemoryListButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
};

ProductMemoryListButton.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default withStyles(styles)(ProductMemoryListButton);
