import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductHeroImage from './viinimaa';

function ProductHeroImage(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductHeroImage {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaProductHeroImage labelPosition="bottom_left" {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductHeroImage {...props} />;
  }

  return null;
}

export default ProductHeroImage;
