import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import AnoraProMemoryListPage from './anoraPro';

function MemoryListPage(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProMemoryListPage {...props} />;
  }

  return null;
}

export default MemoryListPage;
