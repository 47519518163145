import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Launch } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { purchaseProduct } from '../../../../../utils/analyticUtils';

import Button from '../../../Button';
import Visible from '../../../Visible';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    maxWidth: '100%',
  },

  button: {
    ...theme.palette.button,
    display: 'flex',
    width: '100%',
    padding: '13px 15px',
    fontSize: '12px',
    fontWeight: '700',

    '@media print': {
      display: 'none',
    },
  },
});

function ProductRetailLinks({ className, classes, productData, location, ...otherProps }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['cb']);

  const retailUrls = JSON.parse(get(productData, 'attributes.retails_urls.value', '[]'));
  const customerToken = cookies.cb ? cookies.cb.token : '';

  const handleButtonClick = useCallback(() => {
    purchaseProduct(productData, customerToken);
  }, [productData, customerToken]);

  if (isEmpty(retailUrls)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {map(retailUrls, retail => {
        const url = get(retail, 'url');
        const chain = get(retail, 'chain');
        return (
          <Visible hidden={isEmpty(url)}>
            <Button
              key={chain}
              {...otherProps}
              target="_blank"
              variant="outlined"
              className={classes.button}
              to={url}
              endIcon={<Launch fontSize="small" />}
              onClick={handleButtonClick}>
              {chain === 'kesko' && t('ProductRetailLink.kesko.label')}
              {chain === 'sgroup' && t('ProductRetailLink.sgroup.label')}
            </Button>
          </Visible>
        );
      })}
    </div>
  );
}

ProductRetailLinks.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
  location: PropTypes.string,
};

ProductRetailLinks.defaultProps = {
  classes: {},
  className: null,
  productData: null,
  location: null,
};

export default withStyles(styles)(ProductRetailLinks);
