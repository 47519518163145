import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaProductInfo from './viinimaa';
import FolkofolkProductInfo from './folkofolk';

function ProductInfo(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductInfo {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaProductInfo hidePrice {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkProductInfo {...props} />;
  }

  return null;
}

export default ProductInfo;
