import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { Divider, Typography, withStyles } from '@material-ui/core';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: '100%',
    wordBreak: 'break-word',
    hyphens: 'auto',
    padding: theme.palette.productPage.review.articlePadding,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    '&:first-of-type > hr': {
      display: 'none',
    },
  },
  review: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  quoteMark: {
    marginBottom: theme.spacing(1),
    fontSize: '40px',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
  commentWrapper: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  date: { color: theme.palette.common.mediumGray, fontWeight: 'bold' },
  text: {
    marginBottom: theme.spacing(2),
  },
  reviewer: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  divider: {
    background: theme.palette.productPage.review.divider,
    display: 'inline',
    opacity: 0.2,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

function Review({ classes, className, reviewData }) {
  const review = get(reviewData, 'review');
  const reviewer = get(reviewData, 'reviewer');
  const reviewTitle = get(reviewData, 'title');
  return (
    <article className={clsx(classes.root, className)}>
      <Divider className={classes.divider} />
      <div className={classes.review}>
        <FormatQuoteRoundedIcon className={classes.quoteMark} />

        <div className={classes.commentWrapper}>
          {/* <Typography variant="body2" className={classes.date}>MAJ 2021</Typography> */}
          {reviewTitle && (
            <Typography variant="h3" className={classes.text}>
              {reviewTitle}
            </Typography>
          )}
          <Typography className={classes.text}>{review}</Typography>
          {reviewer && <Typography className={classes.reviewer}>- {reviewer}</Typography>}
        </div>
      </div>
    </article>
  );
}

Review.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  reviewData: PropTypes.object,
};

Review.defaultProps = {
  classes: {},
  className: null,
  reviewData: null,
};

export default withStyles(styles)(Review);
