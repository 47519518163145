import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, filter, isEmpty, replace, isEqual, toLower } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionSummary, AccordionDetails, Typography, withStyles, Divider } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InformationRow from '../InformationRow';
import DescriptionRow from '../DescriptionRow';
import { convertToPrice } from '../../../../utils/productUtils';
import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {
    display: 'block',
    marginTop: '5px',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: '90px 0',
    },
    '@media print': {
      padding: '16px 0',
    },
  },
  accordion: {
    backgroundColor: theme.accordion.backgroundColor,
    boxShadow: 'none',
    margin: '0 !important',
    '@media print': {
      backgroundColor: 'transparent',
      '-webkit-print-color-adjust': 'exact',
    },
  },
  accordionDetailsRoot: {
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  accordionTitle: {
    marginBottom: 0,
  },
  accordionSummary: {
    color: theme.palette.productPage.mainInfo.accordion.color,
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    height: '55px',
    minHeight: 0,
    paddingLeft: '0px',
    '@media print': {
      color: 'black',
      backgroundColor: 'transparent',
      borderBottom: '2px solid darkgray',
      ...get(theme, 'palette.productPage.mainInfo.mediaPrint.accordionSummary', {}),
    },
  },
  accordionDetail: {
    display: 'block',
    '@media print': {
      padding: '8px 0',
    },
  },

  accordionDescriptions: {
    '@media print': {
      ...get(theme, 'palette.productPage.mainInfo.mediaPrint.accordionDescriptions', {}),
    },
  },
  accordionAttributes: {
    '@media print': {
      ...get(theme, 'palette.productPage.mainInfo.mediaPrint.accordionAttributes', {}),
    },
  },
  expandIcon: {
    backgroundColor: theme.accordion.expandIcon.backgroundColor,
    borderRadius: '0',
    marginRight: '15px',
    padding: '7px',
    color: theme.palette.productPage.mainInfo.accordion.color,
    '&:hover': {
      backgroundColor: theme.accordion.expandIcon.hover.backgroundColor,
    },
    transform: 'none',
    transition: 'none',
    '@media print': {
      display: 'none',
    },
  },
  hideFromPrint: {
    '@media print': {
      display: 'none',
    },
  },
});

function DotToComma(string, suffix) {
  let splitString = string.split('.');
  splitString[0] = isEmpty(splitString[0]) ? '0' : splitString[0];
  if (!isEmpty(splitString[1])) {
    if (splitString[1].match(/^[0]+$/m)) {
      splitString.pop();
    } else {
      splitString = splitString.join(',');
    }
  }
  return `${splitString} ${suffix}`;
}

function unitConverter(baseUnit) {
  let unit = '';
  if (isEqual(baseUnit, 'LTR')) {
    unit = ' l';
  } else if (isEqual(baseUnit, 'MLT')) {
    unit = ' ml';
  } else if (isEqual(baseUnit, 'CLT')) {
    unit = ' cl';
  } else if (isEqual(baseUnit, 'DLT')) {
    unit = ' dl';
  }
  return unit;
}

function ProductMainInfo({ className, classes, pageData, hideMonopolyInfo, hidePrice, regionAttribute }) {
  const { t } = useTranslation();

  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleTitleClick = useCallback(() => {
    setAccordionOpen(prevAccordionOpen => !prevAccordionOpen);
  }, []);

  const productCategoryLink = filter(get(pageData, 'categories'), [
    'title',
    get(pageData, 'attributes.alcohol_type.value'),
  ]);

  const brand = filter(get(pageData, 'brands'), _brand =>
    toLower(get(_brand, 'title') === toLower(get(pageData, 'attributes.brand_ecomm.value'))),
  );

  const alcoholVolume = isEmpty(get(pageData, 'attributes.alcohol_vol.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.alcohol_vol.value'), ' %');

  const volume = isEmpty(get(pageData, 'attributes.volume.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.volume.value'), unitConverter(get(pageData, 'attributes.base_unit.value')));

  const sugarContent = isEmpty(get(pageData, 'attributes.sugar_content.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.sugar_content.value'), ' g/litra');

  const price = useMemo(() => {
    const normPrice = get(pageData, 'price');
    const reducedPrice = get(pageData, 'attributes.monopoly_reduced_price.value');
    return isEmpty(reducedPrice) ? convertToPrice(normPrice) : convertToPrice(reducedPrice);
  }, [pageData]);

  let regionLabel = get(pageData, 'attributes.subregion.label');
  let regionValue = get(pageData, 'attributes.subregion.value');

  if (!isEqual(regionAttribute, 'subregion') && !isEmpty(get(pageData, ['attributes', regionAttribute, 'value']))) {
    regionLabel = get(pageData, ['attributes', regionAttribute, 'label']);
    regionValue = get(pageData, ['attributes', regionAttribute, 'value']);
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Accordion
        classes={{ root: classes.accordion }}
        className={clsx(classes.heading, classes.accordionDescriptions)}
        expanded={accordionOpen}>
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}
          onClick={handleTitleClick}>
          <Typography variant="h3" className={classes.accordionTitle}>
            {t('ProductPage.ProductInfo.AccordionTitle.ProductDescription')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <DescriptionRow
            title={get(pageData, 'attributes.wine_colour.label')}
            value={get(pageData, 'attributes.wine_colour.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.aroma.label')}
            value={get(pageData, 'attributes.aroma.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.taste.label')}
            value={get(pageData, 'attributes.taste.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.how_to_use.label')}
            value={get(pageData, 'attributes.how_to_use.value')}
          />

          <DescriptionRow
            title={get(pageData, 'attributes.how_its_made.label')}
            value={get(pageData, 'attributes.how_its_made.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.story.label')}
            value={get(pageData, 'attributes.story.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.description.label')}
            value={get(pageData, 'attributes.description.value')}
          />

          <DescriptionRow
            title={get(pageData, 'attributes.awards.label')}
            value={get(pageData, 'attributes.awards.value')}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{
          root: clsx(classes.accordion, className, classes.accordionDetailsRoot, classes.accordionAttributes),
        }}
        className={classes.heading}>
        <AccordionSummary
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3" className={classes.accordionTitle}>
            {t('ProductPage.ProductInfo.AccordionTitle.ProductInformation')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.accordionDetail)}>
          <table>
            <tbody>
              <InformationRow
                title={get(pageData, 'attributes.alcohol_type.label')}
                value={get(pageData, 'attributes.alcohol_type.value')}
                to={get(productCategoryLink, '0.fullPath')}
              />
              <InformationRow
                title={get(pageData, 'attributes.fullbodiness.label')}
                value={get(pageData, 'attributes.fullbodiness.value')}
              />
              <InformationRow title={get(pageData, 'attributes.alcohol_vol.label')} value={alcoholVolume} />
              {!hideMonopolyInfo && (
                <InformationRow
                  title={
                    !isEmpty(get(pageData, 'attributes.monopoly_number.label'))
                      ? get(pageData, 'attributes.monopoly_number.label')
                      : t('ProductPage.ProductInfo.MonopolyNumber')
                  }
                  value={get(pageData, 'attributes.monopoly_number.value')}
                />
              )}

              <InformationRow title={get(pageData, 'attributes.volume.label')} value={volume} />
              <InformationRow
                title={get(pageData, 'attributes.brand_ecomm.label')}
                value={get(pageData, 'attributes.brand_ecomm.value')}
                to={get(brand, '0.fullPath')}
              />
              <InformationRow
                title={get(pageData, 'attributes.closure.label')}
                value={get(pageData, 'attributes.closure.value')}
              />
              <InformationRow
                title={get(pageData, 'attributes.packaging_type.label')}
                value={get(pageData, 'attributes.packaging_type.value')}
              />
              <InformationRow title={regionLabel} value={regionValue} />
              <InformationRow title={get(pageData, 'attributes.sugar_content.label')} value={sugarContent} />
              <InformationRow
                title={get(pageData, 'attributes.grapes.label')}
                value={
                  !isEmpty(get(pageData, 'attributes.grapes.value'))
                    ? replace(replace(get(pageData, 'attributes.grapes.value'), /,+/g, ', '), /\.+/g, '')
                    : null
                }
              />
              <InformationRow
                title={get(pageData, 'attributes.sweetness.label')}
                value={get(pageData, 'attributes.sweetness.value')}
              />
              <InformationRow title="Tuotenumero" value={get(pageData, 'sku')} />
              <Visible hidden={hidePrice}>
                <InformationRow
                  className={classes.hideFromPrint}
                  title={get(
                    pageData,
                    ['attributes', 'mopoly_price', 'label'],
                    t('ProductPage.ProductInfo.WholesalePrice'),
                  )}
                  value={`${price} ${t('ProductMonopolyPrice.currency.symbol')}`}
                />
              </Visible>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.hideFromPrint} />
    </div>
  );
}

ProductMainInfo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  hideMonopolyInfo: PropTypes.bool,
  hidePrice: PropTypes.bool,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  regionAttribute: PropTypes.string,
};

ProductMainInfo.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  hideMonopolyInfo: false,
  hidePrice: true,
  regionAttribute: 'subregion',
};

export default withStyles(styles)(ProductMainInfo);
