import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import AnoraProMemoryListShareButton from './anoraPro';

function MemoryListShareButton(props) {
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <AnoraProMemoryListShareButton {...props} />;
  }

  return null;
}

export default MemoryListShareButton;
