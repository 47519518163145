import React from 'react';

import { graphql } from 'gatsby';

import CommonAdvancedPageTemplate from './AdvancedPageTemplate';
import AnoraProAdvancedPageTemplate from './anoraPro';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function AdvancedPageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProAdvancedPageTemplate {...props} />;
  }
  return <CommonAdvancedPageTemplate {...props} />;
}
export const pageQuery = graphql`
  query AdvancedPageTemplate($pageId: String!) {
    contentfulAdvancedPage(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      languageCode
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      content {
        ...ContentFragment
      }
      relatedArticlePages {
        ...LatestArticlesFragment
      }
      relatedContactPersons {
        ...ContactPersonFragment
      }
      relatedPages {
        ...RelatedPageFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default AdvancedPageTemplate;
