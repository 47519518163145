import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import map from 'lodash/map';
import get from 'lodash/get';
import { withStyles, Grid, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProductCard from '../../../shared/Products/ProductCard';
import { useMemoryList } from '../../../hooks/useMemoryList';
import PrintButton from '../../../shared/PrintButton';
import MemoryListShareButton from '../../MemoryListShareButton';
import ProductInfo from '../../../page/ProductInfo';
import { viewCart } from '../../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '@media print': {
      padding: 0,
    },
  },
  title: {
    padding: theme.spacing(4, 0),
  },
  header: {
    position: 'relative',
    marginBottom: theme.spacing(6),
    '@media print': {
      display: 'none !important',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '0px',
    '@media print': {
      display: 'block',
    },
  },
  productCard: {
    '@media print': {
      display: 'none',
    },
  },
  productInfo: {
    display: 'none',
    '@media print': {
      display: 'block',
      minHeight: '800px',
      paddingTop: theme.spacing(4),
      pageBreakAfter: 'always',
    },
  },
});

function MemoryListPage({ classes, className, pageData }) {
  const { t } = useTranslation();
  const { getProductsInMemoryList } = useMemoryList();
  const productList = getProductsInMemoryList(pageData);

  useEffect(() => {
    viewCart(productList);
  }, [productList]);

  if (productList.length <= 0) {
    return null;
  }

  return (
    <Container className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          {t('MemoryList.MemoryListPage.Proudcts.Title')}
        </Typography>
        <Grid container justifyContent="flex-end" spacing={1} alignItems="center">
          <Grid item>
            <MemoryListShareButton />
          </Grid>
          <Grid item>
            <PrintButton />
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        {map(productList, product => {
          return (
            <React.Fragment key={get(product, 'id')}>
              <ProductCard
                productData={product}
                key={get(product, 'id')}
                hasRemoveAction
                className={classes.productCard}
              />
              <ProductInfo pageData={product} className={classes.productInfo} />
            </React.Fragment>
          );
        })}
      </div>
    </Container>
  );
}

MemoryListPage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MemoryListPage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default memo(withStyles(styles)(MemoryListPage));
