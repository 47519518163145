import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core';

import ThumbnailImage from '../../../ThumbnailImage';
import Link from '../../../../navigation/Link';
import Visible from '../../../Visible';
import Button from '../../../Button';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  categoryButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.category.outlined.text,
    fontSize: '12px',
    marginRight: theme.spacing(2),
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    background: 'none',
    padding: theme.spacing(1),
    border: '0px',
    '&:hover': {
      color: theme.palette.category.hoverText,
      border: '0px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  categoryLink: {
    margin: theme.spacing(1, 4, 1, 0),
    '@media print': {
      marginRight: theme.spacing(1),
    },
  },
  categoryIconImage: {
    borderRadius: '0',
    '@media print': {
      height: 'auto',
      maxWidth: '30px',
    },
  },
});

function ProductFoodRecommendationCategories({ classes, className, productData }) {
  const foodRecommendationCategories = get(productData, 'foodRecommendationCategories');

  if (isEmpty(foodRecommendationCategories)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {map(foodRecommendationCategories, (category, index) => {
        const categoryId = get(category, 'contentful_id');
        const categoryKey = `${categoryId}-${index}`;
        const title = get(category, 'title');
        const fullPath = get(category, 'fullPath');
        const categoryIcon = get(category, 'categoryIcon.0');

        return (
          <React.Fragment key={categoryKey}>
            <Visible visible={isEmpty(categoryIcon)}>
              <Button to={fullPath} size="small" className={classes.categoryButton}>
                {title}
              </Button>
            </Visible>
            <Visible visible={!isEmpty(categoryIcon)}>
              <Link to={fullPath} size="small" className={classes.categoryLink}>
                <ThumbnailImage
                  className={classes.categoryIconImage}
                  transformation="SearchProductThumbnailImage-IN-XS"
                  data={categoryIcon}
                  width="auto"
                  height={50}
                />
              </Link>
            </Visible>
          </React.Fragment>
        );
      })}
    </div>
  );
}

ProductFoodRecommendationCategories.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  outlined: PropTypes.bool,
  productData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductFoodRecommendationCategories.defaultProps = {
  classes: {},
  className: null,
  outlined: false,
  productData: null,
};

export default withStyles(styles)(ProductFoodRecommendationCategories);
