import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaProductMainInfo from './viinimaa';
import FolkofolkProductMainInfo from './folkofolk';

function ProductMainInfo(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductMainInfo {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return (
      <ViinimaaProductMainInfo
        hidePrice={false}
        hideMonopolyInfo
        regionAttribute="anora_pro_region_classification"
        {...props}
      />
    );
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkProductMainInfo {...props} />;
  }

  return null;
}

export default ProductMainInfo;
