import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual } from 'lodash';
import { Typography, withStyles } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

const styles = theme => ({
  root: {
    '@media print': {
      marginLeft: '-10px',
    },
  },
  category: {
    display: 'flex',
  },
  categoryIcon: {
    width: '27px',
    height: '27px',

    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  },
  circle: {
    fontSize: '16px',
    color: 'white',
  },
  title: {
    padding: '4px 10px ',
    backgroundColor: 'white',
    fontSize: '13px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  lempeäMakeahko: { backgroundColor: theme.palette.productPage.tasteProfiles.orange },
  pehmeäKepeä: { backgroundColor: theme.palette.productPage.tasteProfiles.yellow },
  pirteäHedelmäinen: { backgroundColor: theme.palette.productPage.tasteProfiles.green },
  runsasPaahteinen: { backgroundColor: theme.palette.productPage.tasteProfiles.brown },
  vivahteikasRyhdikäs: { backgroundColor: theme.palette.productPage.tasteProfiles.blue },

  meheväHilloinen: { backgroundColor: theme.palette.productPage.tasteProfiles.pink },
  rotevaVoimakas: { backgroundColor: theme.palette.productPage.tasteProfiles.darkBrown },
  vivahteikasKehittynyt: { backgroundColor: theme.palette.productPage.tasteProfiles.brightOrange },
  marjaisaRaikas: { backgroundColor: theme.palette.productPage.tasteProfiles.purple },
  pehmeäHedelmäinen: { backgroundColor: theme.palette.productPage.tasteProfiles.lightPink },
});
/*
alko_taste_styles =>
    Lempeä & Makeahko,
    Pehmeä & Kepeä,
    Pirteä & Hedelmäinen,
    Runsas & Paahteinen
    Vivahteikas & Ryhdikäs,

    Mehevä & Hilloinen,
    Roteva & Voimakas,
    Vivahteikas & Kehittynyt,
    Marjaisa & Raikas,
    Pehmeä & Hedelmäinen,

*/

function ProductTasteStyleCategories({ className, classes, productData }) {
  const tasteStyleCategory = get(productData, 'tasteStyleCategories.0');
  const tasteTitle = get(tasteStyleCategory, 'title');

  if (isEmpty(tasteStyleCategory)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.category)}>
        <div
          className={clsx(
            classes.categoryIcon,
            isEqual(tasteTitle, 'Lempeä & Makeahko') ? classes.lempeäMakeahko : null,
            isEqual(tasteTitle, 'Pehmeä & Kepeä') ? classes.pehmeäKepeä : null,
            isEqual(tasteTitle, 'Pirteä & Hedelmäinen') ? classes.pirteäHedelmäinen : null,
            isEqual(tasteTitle, 'Runsas & Paahteinen') ? classes.runsasPaahteinen : null,
            isEqual(tasteTitle, 'Vivahteikas & Ryhdikäs') ? classes.vivahteikasRyhdikäs : null,
            isEqual(tasteTitle, 'Mehevä & Hilloinen') ? classes.meheväHilloinen : null,
            isEqual(tasteTitle, 'Roteva & Voimakas') ? classes.rotevaVoimakas : null,
            isEqual(tasteTitle, 'Vivahteikas & Kehittynyt') ? classes.vivahteikasKehittynyt : null,
            isEqual(tasteTitle, 'Marjaisa & Raikas') ? classes.marjaisaRaikas : null,
            isEqual(tasteTitle, 'Pehmeä & Hedelmäinen') ? classes.pehmeäHedelmäinen : null,
          )}>
          <FiberManualRecord className={classes.circle} />
        </div>
        <Typography variant="body1" className={classes.title}>
          {tasteTitle}
        </Typography>
      </div>
    </div>
  );
}

ProductTasteStyleCategories.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductTasteStyleCategories.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default withStyles(styles)(ProductTasteStyleCategories);
