import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import SeoFields from '../../components/page/SeoFields';
import HeroImage from '../../components/page/HeroImage';
import Title from '../../components/page/Title';
import Content from '../../components/page/Content';
import RelatedPages from '../../components/page/RelatedPages';
import LatestArticles from '../../components/page/LatestArticles';
import ShortDescription from '../../components/page/ShortDescription';
import SiteNotification from '../../components/page/SiteNotifications';
import AnalyticsModule from '../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../components/shared/ThemeStyle/withSecondaryTheme';
import PlayableIframeScript from '../../components/page/PlayableIframeScript';
import SearchResultPage from '../../components/search/SearchResultPage';
import MemoryListPage from '../../components/memorylist/MemoryListPage';

import PageBrowseTracker from '../../components/page/PageBrowseTracker';

import useInitI18next from '../../components/hooks/useInitI18next';
import useInitLuxon from '../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
    '@media print': {
      paddingTop: '0 !important',
    },
  },
  hideFromPrint: {
    '@media print': {
      display: 'none !important',
    },
  },
});

function AdvancedPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulAdvancedPage');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();
  const isSearchResultPage = get(pageData, 'id') === get(pageData, ['searchPage', 'id']);
  const containerWidth = isSearchResultPage ? 'lg' : '';

  const isMemoryListPage = get(pageData, 'id') === get(pageData, 'mainTheme.memoryListPage.id');

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <PlayableIframeScript pageData={pageData} />
      <Header pageData={pageData} classes={isMemoryListPage && { root: classes.hideFromPrint }} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <Title pageData={pageData} maxWidth={containerWidth} className={isMemoryListPage && classes.hideFromPrint} />
        <ShortDescription pageData={pageData} className={isMemoryListPage && classes.hideFromPrint} />
        <HeroImage
          pageData={pageData}
          disableGutters
          disableMaxWidth
          className={isMemoryListPage && classes.hideFromPrint}
        />
        <Content pageData={pageData} className={isMemoryListPage && classes.hideFromPrint} />
        {isMemoryListPage && <MemoryListPage pageData={pageData} />}
        {isSearchResultPage && <SearchResultPage pageData={pageData} />}
        <RelatedPages pageData={pageData} />
        <LatestArticles pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

AdvancedPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AdvancedPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withSecondaryTheme(withStyles(styles)(AdvancedPageTemplate));
