import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductRetailLinks from './viinimaa';

function ProductRetailLinks(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductRetailLinks {...props} />;
  }

  return null;
}

export default ProductRetailLinks;
