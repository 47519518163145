import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, filter, isEmpty, replace, isEqual, isUndefined, toLower } from 'lodash';
import { Accordion, AccordionSummary, AccordionDetails, Typography, withStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakPoints } from '../../../../constants';

import InformationRow from '../InformationRow';
import DescriptionRow from '../DescriptionRow';
import ProductFoodRecommendationCategories from '../../../shared/Products/ProductFoodRecommendationCategories';
import ProductReviews from '../../ProductReviews';
import Visible from '../../../shared/Visible';

const styles = theme => ({
  root: {
    display: 'block',
    marginTop: '5px',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: { padding: '90px 0' },
    '@media print': {
      padding: '16px',
    },
  },
  accordion: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    margin: '0 !important',
    '@media print': {
      backgroundColor: 'transparent',
    },
  },
  accordionDetailsRoot: {
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  accordionTitle: {
    marginBottom: 0,
  },
  accordionSummary: {
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    height: '55px',
    minHeight: 0,
    paddingLeft: '0px',
  },
  accordionSummary1: {
    color: theme.palette.productPage.mainInfo.accordion.description.summaryText,
    backgroundColor: theme.palette.productPage.mainInfo.accordion.description.summaryBackground,
    '@media print': {
      color: 'black',
      backgroundColor: 'transparent',
      borderBottom: '2px solid darkgray',
    },
  },
  accordionSummary2: {
    color: theme.palette.productPage.mainInfo.accordion.information.summaryText,
    backgroundColor: theme.palette.productPage.mainInfo.accordion.information.summaryBackground,
    '@media print': {
      color: 'black',
      backgroundColor: 'transparent',
      borderBottom: '2px solid darkgray',
    },
  },
  accordionDetail: {
    display: 'block',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(3, 0) },
    '@media print': {
      padding: '8px 0',
    },
  },
  expandIcon: {
    backgroundColor: theme.palette.productPage.mainInfo.accordion.iconBackground,
    borderRadius: '0',
    marginRight: '25px',
    marginLeft: '15px',
    padding: '7px',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gray,
      color: theme.palette.common.darkerBrown,
    },
    transform: 'none',
    transition: 'none',
    '@media print': {
      display: 'none',
    },
  },
  reviews: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  categories: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    '@media print': {
      display: 'none',
    },
  },
});

function DotToComma(string, suffix) {
  let splitString = string.split('.');
  splitString[0] = isEmpty(splitString[0]) ? '0' : splitString[0];

  if (!isEmpty(splitString[1])) {
    if (splitString[1].match(/^[0]+$/m)) {
      splitString.pop();
    } else {
      splitString = splitString.join(',');
    }
  }
  return `${splitString} ${suffix}`;
}

function unitConverter(baseUnit) {
  let unit = '';
  if (isEqual(baseUnit, 'LTR')) {
    unit = ' l';
  } else if (isEqual(baseUnit, 'MLT')) {
    unit = ' ml';
  } else if (isEqual(baseUnit, 'CLT')) {
    unit = ' cl';
  } else if (isEqual(baseUnit, 'DLT')) {
    unit = ' dl';
  }
  return unit;
}

function ProductMainInfo({ className, classes, pageData }) {
  const [isMobile, setIsMobile] = useState(true);
  const { t } = useTranslation();

  const { width } = useWindowSize();

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleTitleClick = useCallback(() => {
    setAccordionOpen(prevAccordionOpen => !prevAccordionOpen);
  }, []);

  const productCategoryLink = filter(get(pageData, 'categories'), [
    'title',
    get(pageData, 'attributes.alcohol_type.value'),
  ]);

  const brand = filter(get(pageData, 'brands'), _brand =>
    toLower(get(_brand, 'title') === toLower(get(pageData, 'attributes.brand_ecomm.value'))),
  );

  const alcoholVolume = isEmpty(get(pageData, 'attributes.alcohol_vol.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.alcohol_vol.value'), ' %');

  const volume = isEmpty(get(pageData, 'attributes.volume.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.volume.value'), unitConverter(get(pageData, 'attributes.base_unit.value')));
  const sugarContent = isEmpty(get(pageData, 'attributes.sugar_content.value'))
    ? null
    : DotToComma(get(pageData, 'attributes.sugar_content.value'), ' g/liter');

  return (
    <div className={clsx(classes.root, className)}>
      <Accordion classes={{ root: classes.accordion }} className={classes.heading} expanded={accordionOpen}>
        <AccordionSummary
          className={clsx(classes.accordionSummary, classes.accordionSummary1)}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMore />}
          onClick={handleTitleClick}>
          <Typography variant="h3" className={classes.accordionTitle}>
            {t('ProductPage.ProductInfo.AccordionTitle.ProductDescription')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <DescriptionRow
            title={get(pageData, 'attributes.description.label')}
            value={get(pageData, 'attributes.description.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.aroma.label')}
            value={get(pageData, 'attributes.aroma.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.wine_colour.label')}
            value={get(pageData, 'attributes.wine_colour.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.awards.label')}
            value={get(pageData, 'attributes.awards.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.how_its_made.label')}
            value={get(pageData, 'attributes.how_its_made.value')}
          />
          <DescriptionRow
            title={get(pageData, 'attributes.how_to_use.label')}
            value={get(pageData, 'attributes.how_to_use.value')}
          />
          <ProductFoodRecommendationCategories productData={pageData} className={classes.categories} />
          <DescriptionRow
            title={get(pageData, 'attributes.story.label')}
            value={get(pageData, 'attributes.story.value')}
          />
        </AccordionDetails>
      </Accordion>
      <Visible visible={isMobile}>
        <ProductReviews pageData={pageData} />
      </Visible>
      <Accordion
        classes={{ root: clsx(classes.accordion, className, classes.accordionDetailsRoot) }}
        className={classes.heading}>
        <AccordionSummary
          className={clsx(classes.accordionSummary, classes.accordionSummary2)}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMore />}>
          <Typography variant="h3" className={classes.accordionTitle}>
            {t('ProductPage.ProductInfo.AccordionTitle.ProductInformation')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetail}>
          <table>
            <tbody>
              <InformationRow
                title={get(pageData, 'attributes.alcohol_type.label')}
                value={get(pageData, 'attributes.alcohol_type.value')}
                to={get(productCategoryLink, '0.fullPath')}
              />
              <InformationRow
                title={
                  !isEmpty(get(pageData, 'attributes.monopoly_sortiment.label'))
                    ? get(pageData, 'attributes.monopoly_sortiment.label')
                    : t('ProductPage.ProductInfo.MonopolySortiment')
                }
                value={get(pageData, 'attributes.monopoly_sortiment.value')}
              />
              <InformationRow title={get(pageData, 'attributes.alcohol_vol.label')} value={alcoholVolume} />
              <InformationRow
                title={
                  !isEmpty(get(pageData, 'attributes.monopoly_number.label'))
                    ? get(pageData, 'attributes.monopoly_number.label')
                    : t('ProductPage.ProductInfo.MonopolyNumber')
                }
                value={get(pageData, 'attributes.monopoly_number.value')}
              />
              <InformationRow title={get(pageData, 'attributes.volume.label')} value={volume} />
              <InformationRow
                title={get(pageData, 'attributes.brand_ecomm.label')}
                value={get(pageData, 'attributes.brand_ecomm.value')}
                to={get(brand, '0.fullPath')}
              />
              <InformationRow
                title={get(pageData, 'attributes.closure.label')}
                value={get(pageData, 'attributes.closure.value')}
              />
              <InformationRow
                title={get(pageData, 'attributes.packaging_type.label')}
                value={get(pageData, 'attributes.packaging_type.value')}
              />
              <InformationRow
                title={get(pageData, 'attributes.packing_material.label')}
                value={get(pageData, 'attributes.packing_material.value')}
              />
              <InformationRow
                title={get(pageData, 'attributes.subregion.label')}
                value={get(pageData, 'attributes.subregion.value')}
              />
              <InformationRow title={get(pageData, 'attributes.sugar_content.label')} value={sugarContent} />
              <InformationRow
                title={get(pageData, 'attributes.grapes.label')}
                value={
                  !isEmpty(get(pageData, 'attributes.grapes.value'))
                    ? replace(replace(get(pageData, 'attributes.grapes.value'), /,+/g, ', '), /\.+/g, '')
                    : null
                }
              />
              <InformationRow
                title={
                  !isEmpty(get(pageData, 'attributes.storage_information.label'))
                    ? get(pageData, 'attributes.storage_information.label')
                    : t('ProductPage.ProductInfo.StorageInformation')
                }
                value={get(pageData, 'attributes.storage_information.value')}
              />
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
      <Divider className={classes.divider} />
    </div>
  );
}

ProductMainInfo.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductMainInfo.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ProductMainInfo);
