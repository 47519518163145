import React from 'react';

import { Helmet } from 'react-helmet';

function NoIndex() {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
}

export default NoIndex;
