import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { get, isNull, isEmpty } from 'lodash';
import Link from '../../navigation/Link';

const styles = theme => ({
  root: {},
  key: {
    padding: '12px 24px 10px 0px',
    color: theme.palette.productPage.mainInfo.accordion.information.detailesKey,

    fontWeight: 'bold',
    textTransform: 'uppercase',
    '@media print': {
      padding: '2px 24px 2px 0px',
      fontSize: '0.8rem',
      ...get(theme, 'palette.productPage.mainInfo.mediaPrint.informationRow.key', {}),
    },
  },
  keyCell: {
    verticalAlign: 'text-top',
  },
  value: {
    color: theme.palette.common.black,
    '@media print': {
      ...get(theme, 'palette.productPage.mainInfo.mediaPrint.informationRow.value', {}),
      fontSize: '0.8rem',
    },
  },
  valueCell: {
    verticalAlign: 'text-top',
  },
  LinkValue: {
    color: theme.palette.link.link,
  },
});

function InformationRow({ className, classes, title, value, to }) {
  if (isNull(value) || isEmpty(value)) {
    return null;
  }

  return (
    <tr className={clsx(className, classes.root)}>
      <td className={classes.keyCell}>
        <Typography variant="body2" className={classes.key}>
          {title}
        </Typography>
      </td>
      {isEmpty(to) ? (
        <td className={classes.valueCell}>
          <Typography variant="body1" className={classes.value}>
            {value}
          </Typography>
        </td>
      ) : (
        <td>
          <Link to={to}>
            <Typography variant="body1" className={classes.LinkValue}>
              {value}
            </Typography>
          </Link>
        </td>
      )}
    </tr>
  );
}

InformationRow.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InformationRow.defaultProps = {
  classes: {},
  className: null,
  title: '',
  value: null,
  to: null,
};

export default withStyles(styles)(InformationRow);
