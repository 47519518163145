import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Container, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { get, isEqual, map } from 'lodash';
import Review from './Review';

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.productPage.review.background,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0),
    },
    '@media print': {
      display: 'none',
    },
  },
  reviews: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  review: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: ({ pageData }) => {
        const criticReviews = get(pageData, 'attributes.review_data.value');

        if (criticReviews && JSON.parse(criticReviews).length > 2) {
          return 'calc(100% / 3)';
        }
        return '50%';
      },
    },
  },
  container: {},
  title: {
    padding: theme.palette.productPage.review.articlePadding,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

function ProductReviews({ className, classes, pageData }) {
  const { t } = useTranslation();
  let criticReviews = get(pageData, 'attributes.review_data.value');

  if (criticReviews && !isEqual(criticReviews, '[]')) {
    criticReviews = JSON.parse(criticReviews);
    return (
      <div className={clsx(classes.root, className)}>
        <Container className={classes.container}>
          <div className={classes.reviews}>
            {map(criticReviews, (criticReview, index) => {
              return <Review key={index} reviewData={criticReview} className={classes.review} />;
            })}
          </div>
        </Container>
      </div>
    );
  }
  return null;
}

ProductReviews.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductReviews.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ProductReviews);
